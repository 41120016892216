import { AxiosRequestConfig } from "axios";
import axios, { API } from "./axios";

export class Headers {
    static HTTP_HEADER_X_INCLUDE_EXECUTION_RESPONSE = 'x-include-execution-response';
    static HTTP_HEADER_X_FIELDS = 'x-fields';
    static HTTP_HEADER_X_API_KEY = 'x-api-key';
    static HTTP_HEADER_X_DEPLOYMENT_ID = 'x-deployment-id';
    static HTTP_HEADER_X_X509_SUBJECTDN_OU = 'x-x509-subjectdn-ou';
    static HTTP_HEADER_X_X509_SUBJECTDN_DC = 'x-x509-subjectdn-dc';
    static HTTP_HEADER_X_X509_VERIFY = 'x-x509-verify';
    static HTTP_HEADER_X_CLIENT_ID = 'x-client-id';
    static HTTP_HEADER_X_SERVICE = 'x-service';
    static HTTP_HEADER_X_FORCE = 'x-force';
    static HTTP_HEADER_X_UPSERT = 'x-upsert'; // Tells the API that a PUT request can be an Upsert (ie. insert or update)
    static HTTP_HEADER_X_CDC_TABLE = 'x-cdc-table';
    static HTTP_HEADER_X_CDC_OPERATION = 'x-cdc-operation'; // "delete" or "upsert"
    static HTTP_HEADER_X_SOURCE_CLIENT = 'x-source-client';  // core or edge
    static HTTP_HEADER_X_SOURCE_SERVICE = 'x-source-service';
    static HTTP_HEADER_X_TARGET_CLIENT = 'x-target-client';  // core or edge
    static HTTP_HEADER_X_TARGET_SERVICE = 'x-target-service';
    static HTTP_HEADER_X_REQUEST_TIMESTAMP = 'x-request-timestamp'; // the timestamp when the request was sent
    static HTTP_HEADER_X_REQUEST_METHOD = 'x-request-method';
    static HTTP_HEADER_X_REQUEST_URI = 'x-request-uri';
    static HTTP_HEADER_X_EVENT_TYPE = 'x-event-type'; // crud or log
    static HTTP_HEADER_X_SEND_REPLY = 'x-send-reply'; // In request/reply, should http connector send a reply
    static HTTP_HEADER_X_DISCOVERED_EDGE_ID = 'x-discovered-edge-id';
    static HTTP_HEADER_X_CONNECTOR_MODE = 'x-connector-mode'; // async, sync (default is sync)
    static HTTP_HEADER_X_CONNECTOR_REPLY_URI = 'x-connector-reply-uri'; // eg: http://pubsub-connect/api/v1/reply/${id}
    static HTTP_HEADER_X_CONNECTOR_REPLY_METHOD = 'x-connector-reply-method'; // eg: POST
    static HTTP_HEADER_X_REPLY_METHOD = 'x-reply-method'; // eg: PUT
    static HTTP_HEADER_X_REPLY_URI = 'x-reply-uri'; // eg: /api/v1/connectorrequests/${id}
    static HTTP_HEADER_X_REPLY_ATTRIBUTE = 'x-reply-attribute'; // put the reply in an attribute instead of being the body
    static HTTP_HEADER_X_REQUEST_TYPE = 'x-request-type'; // eg: CommandRequest, Upgrade, ActivationRequestApproved
    static HTTP_HEADER_X_DEBUG = 'x-debug';
    static HTTP_HEADER_X_CONNECTOR_HEARTBEAT_URI = 'x-connector-heartbeat-uri';
    static HTTP_HEADER_X_REPLY_SERVICE = 'x-reply-service';
    static HTTP_HEADER_X_INTERNAL = 'x-internal';
    static HTTP_HEADER_X_GITLAB_TOKEN = 'x-gitlab-token';
    static HTTP_HEADER_X_GITLAB_EVENT = 'x-gitlab-event';
    static HTTP_HEADER_X_GITLAB_EVENT_UUID = 'x-gitlab-event-uuid';
    static HTTP_HEADER_X_DISABLE_AUTO_MATCH = 'x-disable_auto_match';
    static HTTP_HEADER_X_WEBSOCKET_MODE = 'x-websocket-mode';
    static HTTP_HEADER_X_IDENTITY_MANAGEMENT_ID = 'x-identity-management-id';
    static HTTP_HEADER_X_TRANSPORT = 'x-transport';
    static HTTP_HEADER_X_FILTER = 'x-filter';
    static HTTP_HEADER_X_FILTER_USE_OR = 'x-filter-use-or'; // use logical OR instead of AND which is the default when multiple params exist
    static HTTP_HEADER_X_ASYNC = 'x-async';
    static HTTP_HEADER_X_SORT = 'x-sort';
    static HTTP_HEADER_X_SEARCH = 'x-search';
    static HTTP_HEADER_X_PAGINATION = 'x-pagination'; // page (default: 1), size (default: 20, max: 50)
    static HTTP_HEADER_X_CORRELATION_ID = 'x-correlation-id';
    static HTTP_HEADER_X_BYPASS_TRANSIENT_ATTRIBUTES = 'x-bypass-transient-attributes';
    static HTTP_HEADER_X_BYPASS_CRUD_HOOKS = 'x-bypass-crud-hooks';
    static HTTP_HEADER_X_SEND_EMPTY_RESPONSE = 'x-send-empty-response'; // When you don't need the PUT/POST response
    static HTTP_HEADER_X_VAULT_TOKEN = 'X-Vault-Token';
    static HTTP_HEADER_X_PUBSUB_TIMEOUT = 'x-pubsub-timeout';
    static HTTP_HEADER_X_BYPASS_WARNINGS = 'x-bypass-warnings';
    static HTTP_HEADER_X_FORCE_SYNC = 'x-force-sync';
    static TRANSPORT_INTERNAL = 'internal';
}

interface WidgetParams {
    widgetId: string;
    deploymentId: string;
    apiKey: string;
}

export const getWidgetParams = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const pathname = location.pathname;
    if(pathname.startsWith('/')) {
        const widgetId = pathname.replace(/^\//, '');
        const params = Object.fromEntries(urlSearchParams.entries());
        const deploymentId = params['x-deployment-id'];
        const apiKey = params['x-api-key'];
        return { widgetId, deploymentId, apiKey } as WidgetParams;
    }
    throw Error("Pathname not known");
}

interface AutomationJobParams {
    automationId: string;
    apiKey: string;
    deploymentId: string;
    command: string;
}

export const submitJob = async (endpoint: string, id: string, command: string) => {
    const url = `/${endpoint}/${id}/jobs`;
    const { widgetId, deploymentId, apiKey } = getWidgetParams();
    const response = await axios(
        apiKey,
        deploymentId,
        API.CORE,
    ).post(url, {command});
    const data = response.data;
    //console.log({url, command, data});
    return data;
}

const submitAutomationJob = async (automationId: string, command: string) => {
    const url = `/automations/${automationId}/jobs`;
    return submitJob('automations', automationId, command);
}

export const automationRunNow = async (automationId: string) => {
    return submitAutomationJob(automationId, 'RunNow');
}

export const get = async (endpoint: string, id: string, config?: AxiosRequestConfig) => {
    const url = `/${endpoint}/${id}`;
    const { deploymentId, apiKey } = getWidgetParams();
    const response = await axios(
        apiKey,
        deploymentId,
        API.CORE,
    ).get(url, config);
    const data = response.data;
    //console.debug({url, data});
    return data;
}

export const getAll = async (endpoint: string, config?: AxiosRequestConfig) => {
    const url = `/${endpoint}`;
    const { deploymentId, apiKey } = getWidgetParams();
    const response = await axios(
        apiKey,
        deploymentId,
        API.CORE,
    ).get(url, config);
    const data = response.data;
    //console.debug({url, data});
    return data;
}

export const put = async (endpoint: string, id: string, data: any, config?: AxiosRequestConfig) => {
    const url = `/${endpoint}/${id}`;
    const { deploymentId, apiKey } = getWidgetParams();
    const response = await axios(
        apiKey,
        deploymentId,
        API.CORE,
    ).put(url, data, config);
    const responseData = response.data;
    //console.debug({url, data, responseData});
    return responseData;
}

export const getWidget = async () => {
    const { widgetId, deploymentId, apiKey } = getWidgetParams();
    return get('widgets', widgetId);
}

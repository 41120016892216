import React, { FC, useContext, useEffect } from "react";
import { cn, sleep } from "../util/util";
import { useSelector } from "react-redux";
import { automationRunNow, get, put } from "../util/helper";
import { set } from "date-fns";

interface AutomationInputDataDisabled {
    variableName: string;
    variableValue: string;
}

interface AutomationInputDataParams {
    automationId: string;
    runNowOnUpdate?: boolean;
}

export const AutomationInputData: FC<AutomationInputDataParams & HTMLElement> = (props: AutomationInputDataParams & HTMLElement) => {
    const [message, setMessage] = React.useState(" ");
    const [clicked, setClicked] = React.useState(false);
    const [automation, setAutomation] = React.useState<any>();
    const [automationTemplate, setAutomationTemplate] = React.useState<any>();
    const global = useSelector((state: any) => state.global.variables)

    const { className, automationId, runNowOnUpdate } = props;

    const onClickUpdate = async () => {
        setClicked(true);
        const _automation = await put('automations', automationId, automation);
        setAutomation(_automation);
        if (runNowOnUpdate) {
            await automationRunNow(automationId);
        }
        setMessage("Updated");
        setClicked(false);
        setTimeout(() => {
            setMessage(" ");
        }, 2000);
    }

    const handleInputDataChange = (name: string, value: any) => {
        setAutomation({
            ...automation,
            inputData: {
                ...automation.inputData,
                [name]: value,
            }
        });
    }

    const getInputSchema = async () => {
        const automation = await get('automations', automationId);
        if (automation) {
            setAutomation(automation);
            const automationTemplate = await get('automationtemplates', automation.associatedAutomationTemplateId);
            setAutomationTemplate(automationTemplate);
        }
    }

    useEffect(() => {
        console.log("AutomationInputData mounted");
        getInputSchema();
    }, []);

    if (!automation || !automationTemplate) {
        return <div>Loading...</div>;
    }

    const { inputSchema } = automationTemplate;
    const { properties } = inputSchema;
    const placeholderData = automation.inputData;

    function getInputType(type: string, enumValues?: String[], raasifyType?: string) {
        if (type === "string") {
            if (enumValues) {
                return "Select";
            }
            if (raasifyType) {
                return "RelatedInstanceSelect";
            }
            return "Input";
        }
        if (type === "text") {
            return "Textarea";
        }
        if (type === "json") {
            return "JSON";
        }
        if (type === "jsonArray") {
            return "JSON";
        }
        if (type === "boolean") {
            return "Switch";
        }
        if (type === "number") {
            return "Input";
        }
        return "Input";
    }

    return (
        <form className={className}>
            {properties && Object.entries(properties).map(([name, property]: [string, any]) => {
                const {
                    title,
                    type,
                    description,
                    section,
                } = property as any;

                const _title = title || name;
                const _enum = property.enum;
                const _raasifyType = property.raasifyType;
                const inputType = getInputType(type!, _enum, _raasifyType);
                const props: any = {}
                if (_enum) {
                    props.values = _enum;
                }

                return (
                    <div className={`${className}__attribute_panel`}>
                        <div className={`${className}__attribute_label_panel`}>
                            <div className={`${className}__attribute_title`}>{_title}</div>
                            <div className={`${className}__attribute_description`}>{description}</div>
                        </div>
                        {inputType === "Textarea" && 
                            <div key={"div" + name}  className={`${className}__attribute_value_panel`}>
                                <textarea
                                    className={`${className}__attribute_value_textarea`}
                                    name={name}
                                    value={automation.inputData[name] || ""}
                                    onChange={(event) => handleInputDataChange(name, event.target.value)}
                                />
                            </div>
                        }
                        {inputType !== "Textarea" &&
                            <div key={"div" + name}  className={`${className}__attribute_value_panel`}>
                                <input
                                    className={`${className}__attribute_value_input`}
                                    value={automation.inputData[name] || ""}
                                    onChange={(event) => handleInputDataChange(name, event.target.value)}
                                />
                            </div>
                        }
                    </div>
                );

            })}

            <div className={`${className}__button_panel_separator`} />
            <div className={`${className}__button_panel`}>
                <button
                    className={`${className}__update_button`}
                    onClick={onClickUpdate}
                    disabled={clicked}
                >
                    Update
                </button>
                {clicked && <span className={`${className}__update_loader`}></span>}
                {message && <div className={`${className}__update_message`}>{message}</div>}
            </div>
        </form>
    );
}

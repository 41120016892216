import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Column {
    title: string;
    key: string;
}

interface DataParams {
    className?: string;
    variableName: string;
    jsonPath?: string;
    columns: Array<Column>;
}

export const HorizontalDataTable: FC<DataParams & HTMLElement> = (props: DataParams & HTMLElement) => {
    const { variableName, jsonPath, columns, className } = props;
    const [data, setData] = useState<any>(null);

    const global = useSelector((state: any) => state.global.variables)

    useEffect(() => {
        console.debug("HorizontalDataTable mounted");
        console.debug("HorizontalDataTable Columns", columns);
        console.debug("HorizontalDataTable Variable name", variableName);
        console.debug("HorizontalDataTable Json path", jsonPath);

    }, []);

    useEffect(() => {
        try {
            if (global && global[variableName]) {
                let res = global[variableName];
                console.debug("Data changed", res);
                setData(res);
            }
        }
        catch (e) {
            console.debug("Error getting data", props, e);
        }
    }, [global]);

    const getDataFromPath = ( row: any, key: string) => {
        const fullPath = jsonPath ? jsonPath + "." + key : key;
        let keys = fullPath.split(".");
        try {
            if (row) {
                let res = row;
                for (let k of keys) {
                    res = res[k];
                }
                return res;
            }
        }
        catch (e) {
            console.debug("Couldn't get data from path",{row, key, keys, e});
        }
        return "-";
    }

    return (
        <table className={className}>
            <thead>
                <tr>
                    {columns.map((column, index) => {
                        //console.debug("Column:", column);
                        return <th key={index}>{column.title}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 && data.map((row: any, index: number) => {
                    //console.debug("Row", row);
                    return (
                        <tr key={index}>
                            {columns.map((column, index) => {
                                //console.debug("Column:", column);
                                return <td key={index}>{getDataFromPath(row, column.key)}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

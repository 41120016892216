import React from "react";
import { AppContainer } from "./AppContainer";
import store from './redux/store'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client';

import './index.css';
import "@flaticon/flaticon-uicons/css/all/all.css";

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);
root.render(
    <Provider store={store}>
        <AppContainer />
    </Provider>
);


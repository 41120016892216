import React, { FC, useEffect, useRef, useState } from "react";
import { Headers, get, getWidgetParams } from "../util/helper";
import { ENDPOINT } from "../util/types";
import { useDispatch } from "react-redux";
import { updateVariable, updateVariableListItem, deleteVariable, deleteVariableListItem } from "../redux/globalSlice";
import { SpinnerCircularFixed } from 'spinners-react';
import io, {Socket} from "socket.io-client";
import { useAppSelector } from "../redux/hooks";

interface Watch {
    url: string;
    id?: string;
    attribute?: string;
    value?: string;
    variableName: string;
    multi?: boolean;
}

interface EventClientParams {
    watches: Array<Watch>;
}

export const EventClient: FC<EventClientParams&HTMLElement> = (props: EventClientParams&HTMLElement) => {
    const [socket, setSocket] = useState<Socket|undefined>();
    const { widgetId, deploymentId, apiKey } = getWidgetParams();

    const {watches} = props;

    const dispatch = useDispatch()

    const isWatching = (event: any) => {
        for(const w of watches) {
            if(w.id && w.url === event.url && w.id === event.id) {
                return w;
            }
            else if(w.attribute && w.value && w.url === event.url && event[w.attribute] === w.value) {
                return w;
            }
            else if(w.multi && w.url === event.url) {
                // events in a list variable
                return w;
            }
        }
        return null;
    }

    const processEventData = (data: any) => {
        //console.debug("processEventData", data);
        const watch = isWatching(data);
        //console.debug("Watch", watch);
        if(watch) { 
            // Get full data from rest api
            get(data.url, data.id)
                .then((data) => {
                    console.debug("Watched Event", {watch, url: data.url, variableName: watch.variableName, data});
                    if(watch.multi) {
                        dispatch(updateVariableListItem({key: watch.variableName, value: data}));
                    }
                    else {
                        dispatch(updateVariable({key: watch.variableName, value: data}));
                    }
                });
        }
    }

    const processDeleteEventData = (data: any) => {
        console.debug("processDeleteEventData", data);
        const watch = isWatching(data);
        //console.debug("Watch", watch);
        if(watch) { 
            console.debug("Watched Event", {watch, url: data.url, variableName: watch.variableName, data});
            if(watch.multi) {
                dispatch(deleteVariableListItem({key: watch.variableName, value: data}));
            }
            else {
                dispatch(deleteVariable({key: watch.variableName, value: data}));
            }
        }
    }
    
    const connect = () => {
        console.log("connecting to socket.io")
        if(socket) {
            socket?.close();
        }
        const socket_io_path = `/socket.io`;

        const headers: {[key:string]: string} = {
            [Headers.HTTP_HEADER_X_API_KEY]: apiKey,
            [Headers.HTTP_HEADER_X_DEPLOYMENT_ID]: deploymentId,
        }

        // connect to core-logic events
        const service = 'cloud-core-logic';
        headers['x-service'] = service;

        const _socket = io({
            path: socket_io_path,
            extraHeaders: headers,
        });
        _socket.on('event', events => {
            //console.log('on event', events);
            for (const event of events) {
                onEvent(event);
            }
        });
        _socket.on('connecting', function () {
            console.log(`${socket_io_path} connecting ${service}`);
        });
        _socket.on('disconnect', function () {
            console.log(`${socket_io_path} disconnect ${service}`);
        });
        _socket.on('connect_failed', function() {
            console.log(`${socket_io_path} connect_failed ${service}`);
        })
        _socket.on('error', function() {
            console.log(`${socket_io_path} error ${service}`);
        })
        _socket.on('reconnect', function() {
            console.log(`${socket_io_path} reconnect ${service}`);
        })
        _socket.on('reconnecting', function() {
            console.log(`${socket_io_path} reconnecting ${service}`);
        })
        _socket.on('reconnect_failed', function() {
            console.log(`${socket_io_path} reconnect_failed ${service}`);
        })
        _socket.on('connect', function () {
            console.log(`${socket_io_path} connected ${service}`);
        });

        setSocket(_socket);
    }

    const onEvent = (event: any) => {
        //console.log('onEvent New Event:\n%s', JSON.stringify(event, null, 4));
        if(event.operation === "CREATE" || event.operation === "UPDATE") {
            processEventData(event.data);
        }
        else if(event.operation === "DELETE") {
            processDeleteEventData(event.data);
        }
    }

    useEffect(() => {
        console.log("EventClient mounted");
        console.log("Watches", watches);
        connect();
    }, []);

    return <></>;
}

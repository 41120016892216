import type {AxiosResponse} from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import {Headers} from "./helper";
import {getLogger, stringify} from "./util";
import {API_PREFIX} from "./config";
const logger = getLogger();


export enum API {
    USER = "user",
    CORE = "core",
    MARKETPLACE = "marketplace",
    ANALYTICS = "analytics",
}

export function getAPIClient(apiKey: string, deploymentId: string, api: API) {
    const headers: {[index:string]: string} = {
        'X-Requested-With': 'XMLHttpRequest',
        "Content-Type": "application/json",
        [Headers.HTTP_HEADER_X_API_KEY]: apiKey,
        [Headers.HTTP_HEADER_X_DEPLOYMENT_ID]: deploymentId,
        [Headers.HTTP_HEADER_X_INCLUDE_EXECUTION_RESPONSE]: "true",
    };
    return getBaseClient(headers, api, deploymentId);
}

export function getBaseClient(headers: any, api: API, deploymentId: string) {
    let client = axios.create({
        baseURL: `/${api}${API_PREFIX}`,
        headers,
    });

    axiosRetry(client, {
        retries: 3, // Number of retries (Defaults to 3) 
    });

    client.interceptors.request.use(function (config) {
        //const _data: any = {};
        // Remove undefined keys
        //if(config.data) {
        //    for (const [key, value] of Object.entries(config.data)) {
        //        if (value !== undefined) {
        //_data[key] = value;
        //        }
        //    }
        //}
        //config.data = _data;
        //logger.info({config});
        return config;
    }, function (error) {
        // Do something with request error
        return error;
    });
    
    client.interceptors.response.use(
        (response: AxiosResponse) => {
            //const {status, headers, data} = response;
            //logger.info({status, headers, data});
            return response;
        },
        async (error) => {
            if(error.response) {
                const {baseURL, url, method, headers, data} = error.response.config;
                const {status, statusText} = error.response;
                logger.error(`API Error:\nRequest: ${stringify({baseURL, url, method, headers, data})}`
                 + `Response: ${stringify({status, statusText, headers: error.response.headers, data: error.response.data})}`);
            }

            if (error.response.status === 401 || error.response.status === 403) {
                logger.info("Returned status: %s", error.response.status);
            }

            throw error;
        },
    )
    
    return client;
}

export default getAPIClient;

import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { getAll } from "../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { updateVariable } from "../redux/globalSlice";

interface LoaderDataParams {
    url: string,
    headers?: {[index: string]: string};
    getFirstItem?: boolean;
    variableName: string;
    refreshIntervalSeconds?: number;
}

export const Loader: FC<LoaderDataParams&HTMLElement> = (props: LoaderDataParams&HTMLElement) => {
    const { url, headers, getFirstItem, variableName, refreshIntervalSeconds } = props;

    const dispatch = useDispatch();

    const options = headers ? {headers} : undefined;

    const getData = async () => {
        getAll(url, options)
            .then((data) => {
                if(getFirstItem && data && data.length > 0) {
                    data = data[0];                
                }
                console.log("Loader", {url, variableName, data});
                dispatch(updateVariable({key: variableName, value: data}));
            })
            .catch((e) => {
                console.error("Error getting data", e);
            });
    }

    useEffect(() => {
        console.log("Loader mounted");
        // run getData every refreshIntervalSeconds
        getData();
        const interval = setInterval(() => {
            getData();
        }, refreshIntervalSeconds ? refreshIntervalSeconds*1000 : 60000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return <></>;
}


import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type VariableUpdateAction = {
    key: string,
    value: any
}

type GlobalState = {
    variables: {[key: string]: any}
}

const initialState = {
    variables: {}
} as GlobalState

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateVariable: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            state.variables[key] = value;
        },
        deleteVariable: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            // delete the variable
            delete state.variables[key];
        },
        updateVariableListItem: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            // replace the item in the list with value.id
            const index = state.variables[key].findIndex((item: any) => item.id === value.id);
            if(index === -1) {
                state.variables[key].push(value);
            }
            else {
                state.variables[key][index] = value;
            }
        },
        deleteVariableListItem: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            // replace the item in the list with value.id
            const index = state.variables[key].findIndex((item: any) => item.id === value.id);
            if(index >= 0) {
                // delete the item
                state.variables[key].splice(index, 1);
            }
        }
    },
})

export const { updateVariable, updateVariableListItem, deleteVariable, deleteVariableListItem } = globalSlice.actions

export default globalSlice.reducer
import React, { FC, useContext, useEffect } from "react";
import { cn, sleep } from "../util/util";
import { useSelector } from "react-redux";
import { get, put, getAll } from "../util/helper";
import { set } from "date-fns";

interface EndpointInputDataParams {
    variableName: string;
}

export const EndpointInputData: FC<EndpointInputDataParams & HTMLElement> = (props: EndpointInputDataParams & HTMLElement) => {
    const [message, setMessage] = React.useState(" ");
    const [clicked, setClicked] = React.useState(false);
    const [endpoint, setEndpoint] = React.useState<any>();
    const [endpointTemplate, setEndpointTemplate] = React.useState<any>();

    const { className, variableName } = props;

    const global = useSelector((state: any) => state.global.variables)

    /*
    const getEndpointId = async () => {
        let _endpointId
        if(endpointName) {
            // get endpoint ID by name
            const endpoints = await getAll('endpoints', {headers: {"x-filter": `name=${endpointName}`}});
            if(endpoints && endpoints.length > 0) {
                _endpointId = endpoints[0].id;
            }
        }
        else {
            _endpointId = endpointId;
        }
        return _endpointId;
    }*/

    const onClickUpdate = async () => {
        setClicked(true);
        let _endpointId = global[variableName].id;
        const _endpoint = await put('endpoints', _endpointId, endpoint);
        setEndpoint(_endpoint);
        setMessage("Updated");
        setClicked(false);
        setTimeout(() => {
            setMessage(" ");
        }, 2000);
    }

    const handleInputDataChange = (name: string, value: any) => {
        setEndpoint({
            ...endpoint,
            inputData: {
                ...endpoint.inputData,
                [name]: value,
            }
        });
    }

    const handleJSONInputDataChange = (name: string, value: any) => {
        setEndpoint({
            ...endpoint,
            inputData: {
                ...endpoint.inputData,
                [name]: value.split("\n"),
            }
        });
    }

    const getInputSchema = async () => {
        if(!global || !global[variableName]) {
            // return if endpoint is not available
            return;
        }
        if(endpoint && endpoint.id === global[variableName].id && endpoint.updated === global[variableName].updated) {
            // return if endpoint has not changed
            return;
        }
        const _endpoint = global[variableName];
        if (_endpoint) {
            setEndpoint(_endpoint);
            const endpointTemplate = await get('endpointtemplates', _endpoint.associatedEndpointTemplateId);
            setEndpointTemplate(endpointTemplate);
        }
    }

    useEffect(() => {
        getInputSchema();
    }, [global]);

    if (!endpoint || !endpointTemplate) {
        return <div>Loading...</div>;
    }

    const { inputSchema } = endpointTemplate;
    const { properties } = inputSchema;
    const placeholderData = endpoint.inputData;

    function getInputType(type: string, enumValues?: String[], raasifyType?: string) {
        if (type === "string") {
            if (enumValues) {
                return "Select";
            }
            if (raasifyType) {
                return "RelatedInstanceSelect";
            }
            return "Input";
        }
        if (type === "text") {
            return "Textarea";
        }
        if (type === "json") {
            return "JSON";
        }
        if (type === "jsonArray") {
            return "JSON";
        }
        if (type === "array") {
            return "JSON";
        }
        if (type === "boolean") {
            return "Switch";
        }
        if (type === "number") {
            return "Input";
        }
        return "Input";
    }

    return (
        <form className={className}>
            {properties && Object.entries(properties).map(([name, property]: [string, any]) => {
                const {
                    title,
                    type,
                    description,
                    section,
                } = property as any;

                const _title = title || name;
                const _enum = property.enum;
                const _raasifyType = property.raasifyType;
                const inputType = getInputType(type!, _enum, _raasifyType);
                const props: any = {}
                if (_enum) {
                    props.values = _enum;
                }

                let value;
                if(endpoint.inputData && endpoint.inputData[name] && Array.isArray(endpoint.inputData[name])) {
                    value = endpoint.inputData[name].join("\n");
                }
                else if(endpoint.inputData && endpoint.inputData[name]) {
                    value = endpoint.inputData[name];
                }
                else {
                    value = "";
                }

                return (
                    <div className={`${className}__attribute_panel`}>
                        <div className={`${className}__attribute_label_panel`}>
                            <div className={`${className}__attribute_title`}>{_title}</div>
                            <div className={`${className}__attribute_description`}>{description}</div>
                        </div>
                        {inputType === "Textarea" && 
                            <div key={"div" + name}  className={`${className}__attribute_value_panel`}>
                                <textarea
                                    className={`${className}__attribute_value_textarea`}
                                    name={name}
                                    value={value}
                                    onChange={(event) => handleInputDataChange(name, event.target.value)}
                                />
                            </div>
                        }
                        {inputType === "JSON" && 
                            <div key={"div" + name}  className={`${className}__attribute_value_panel`}>
                                <textarea
                                    className={`${className}__attribute_value_textarea`}
                                    name={name}
                                    value={value}
                                    onChange={(event) => handleJSONInputDataChange(name, event.target.value)}
                                />
                            </div>
                        }
                        {inputType !== "Textarea" && inputType !== "JSON" &&
                            <div key={"div" + name}  className={`${className}__attribute_value_panel`}>
                                <input
                                    className={`${className}__attribute_value_input`}
                                    value={value}
                                    onChange={(event) => handleInputDataChange(name, event.target.value.split("\n"))}
                                />
                            </div>
                        }
                    </div>
                );

            })}

            <div className={`${className}__button_panel_separator`} />
            <div className={`${className}__button_panel`}>
                <button
                    className={`${className}__update_button`}
                    onClick={onClickUpdate}
                    disabled={clicked}
                >
                    Update
                </button>
                {clicked && <span className={`${className}__update_loader`}></span>}
                {message && <div className={`${className}__update_message`}>{message}</div>}
            </div>
        </form>
    );
}

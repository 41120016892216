import React, { FC, useContext, useEffect } from "react";
import { automationRunNow, getAll } from "../util/helper";
import { cn, sleep } from "../util/util";
import { useSelector } from "react-redux";

interface AutomationRunNowDisabled {
    variableName: string;
    variableValue: string;
}

interface AutomationRunNowParams {
    automationIds?: Array<string>;
    automationNames?: Array<string>;
    interval?: number;
    buttonLabel?: string;
    children?: React.ReactNode
    buttonSleep?: number;
    disabled: AutomationRunNowDisabled;
    saveResponseToVariable?: string;
}

const getAutomationIds = async (automationNames: Array<string>) => {
    const automationIds: Array<string> = [];
    for(const automationName of automationNames) {
        const automations = await getAll("automations", {headers: {"x-filter": `name=${automationName}`}});
        if(automations && automations.length > 0) {
            automationIds.push(automations[0].id);
        }
    }
    return automationIds;
}

export const AutomationRunNow: FC<AutomationRunNowParams&HTMLElement> = (props: AutomationRunNowParams&HTMLElement) => {
    const [clicked, setClicked] = React.useState(false);
    const global = useSelector((state: any) => state.global.variables)
    
    const { className, automationIds, automationNames, interval, buttonLabel, disabled, buttonSleep, children } = props;

    const onClickRunNow = async () => {
        setClicked(true);
        let _automationIds;
        if(automationIds) {
            _automationIds = automationIds;
        }
        else if(automationNames) {
            _automationIds = await getAutomationIds(automationNames);
        }
        if(_automationIds) {
            for(const automationId of _automationIds) {
                await automationRunNow(automationId);
                if(automationId !== _automationIds[_automationIds.length - 1]) {
                    await sleep(interval || 0)
                }
            }
        }

        await sleep(buttonSleep ? buttonSleep*1000 : 5_000);
        setClicked(false);
    }

    useEffect(() => {
        console.log("AutomationRunNow mounted");
    }, []);

    return (
        <div>
            <button 
                className={className}
                onClick={onClickRunNow}
                disabled={clicked || disabled && global[disabled.variableName] === disabled.variableValue}
            >
                {buttonLabel || children}
            </button>
        </div>
    );
}
